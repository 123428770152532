import TagManager from 'react-gtm-module';
import { useSendEvent } from './useSendEvent';
import { RecordCreateRequest } from '@/generated';

type EVENT_CATEGORIES_TYPES =
    "Itinerary" |
    "Group Planning" |
    "User Authentication";

type EVENT_CATEGORIES_PROPS = {
    [key: string]: EVENT_CATEGORIES_TYPES;
};

export const EVENT_CATEGORIES: EVENT_CATEGORIES_PROPS = {
    itinerary: "Itinerary",
    groupPlanning: "Group Planning",
    userAuth: "User Authentication"
};

type EventActionType = {
    action: string;
    label: string;
};

type EVENT_ACTIONS_NAMES =
    "generateItinerary" |
    "tailorItinerary" |
    "completeTailoring" |
    "replanItinerary" |
    "saveTrip" |
    "completeRegistration" |
    "login" |
    "loginViaGoogle" |
    "navigateToViator" |
    "openGroupPlanning" |
    "sendInvitationEmails" |
    "copyInvitationLink" |
    "copyPersonalInvitationLink" |
    "continueAsGuest";

export const EVENT_ACTIONS: Record<EVENT_ACTIONS_NAMES, EventActionType> = {
    generateItinerary: {
        action: "Generate Itinerary",
        label: "User generated an itinerary",
    },
    tailorItinerary: {
        action: "Tailor Itinerary",
        label: "User clicked on tailor itinerary",
    },
    completeTailoring: {
        action: "Complete Tailoring",
        label: "User completed tailoring the itinerary",
    },
    replanItinerary: {
        action: "Replan Itinerary",
        label: "User clicked on replan itinerary",
    },
    saveTrip: {
        action: "Save Trip",
        label: "User clicked on save trip",
    },
    completeRegistration: {
        action: "Registration Completed",
        label: "User completed registration",
    },
    login: {
        action: "Login Completed",
        label: "User completed login",
    },
    loginViaGoogle: {
        action: "Login Completed Via Google",
        label: "User completed login via Google",
    },
    navigateToViator: {
        action: "Navigate to Viator",
        label: "User clicked on a Viator link",
    },
    openGroupPlanning: {
        action: "Open Group Planning",
        label: "User opened the group planning window",
    },
    sendInvitationEmails: {
        action: "Send Invitation Emails",
        label: "User sent invitation emails",
    },
    copyInvitationLink: {
        action: "Copy Invitation Link",
        label: "User copied the invitation link",
    },
    copyPersonalInvitationLink: {
        action: "Copy Personal Invitation Link",
        label: "User copied the personal invitation link",
    },
    continueAsGuest: {
        action: "Continue as Guest",
        label: "User continued as a guest",
    },
};

export const useGoogleAnalytics = () => {
    const { sendEvent } = useSendEvent();
    const isProduction = import.meta.env.VITE_ENV === 'production';

    const sendGAEvent = (category: EVENT_CATEGORIES_TYPES, action: EventActionType, value?: number) => {
        // Convert action name to a suitable event name
        const eventName = action.action.toLowerCase().replace(/\s+/g, '_');

        sendEvent({
            event: eventName,
            category: category,
            action: action.action,
            label: action.label,
            value: value,
        } as RecordCreateRequest);

        if (!isProduction) {
            console.log("GA Event triggered: ", { category, action: action.action, label: action.label, value });
            return;
        }

        try {
            // Pushing event to the GTM dataLayer using react-gtm-module
            TagManager.dataLayer({
                dataLayer: {
                    event: eventName,
                    category: category,
                    action: action.action,
                    label: action.label,
                    value: value,
                },
            });
        } catch (error) {
            console.error("Error sending GA event", error);
        }
    };

    return {
        sendGAEvent,
        EVENT_ACTIONS,
        EVENT_CATEGORIES,
    };
};
