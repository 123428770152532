import { ReactNode } from 'react';
import classNames from 'classnames';
import { Container } from '../containers/Container';

type Props = {
  heading: string;
  subheading?: string;
  children: ReactNode;
};

export const Step = ({ heading, subheading, children }: Props) => {
  return (
    <Container>
      <div
        className={classNames(
          'w-full flex flex-col items-center gap-1',
          'mt-[32px] mdx:mt-[64px] mb-6',
        )}
      >
        <h4 className="text-h4 text-black text-center">{heading}</h4>
        {subheading && (
          <p className="text-p text-gray text-center max-w-[260px]">
            {subheading}
          </p>
        )}
      </div>
      <div className="w-full flex flex-col items-center gap-y-3">
        {children}
      </div>
    </Container>
  );
};
