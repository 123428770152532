import { useState } from 'react';
import { TailoringInput } from '../inputs/TailoringInput';
import { showToast } from '@/utils/showToast';
import { TailoredSearchTypes } from '@/stores/tailorSearchStore';
import { InvitationSearchTypes } from '@/stores/invitationStore';
import { MAX_SPECIFIC_THINGS_TO_INCLUDE } from '@/utils/constants';

type Params = {
  storage: TailoredSearchTypes | InvitationSearchTypes;
  setStorageSearch: (value: Partial<TailoredSearchTypes>) => void;
};

export const SpecificThingsToInclude = ({
  storage,
  setStorageSearch,
}: Params) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [specificThingsToInclude, setSpecificThingsToInclude] = useState<
    string[]
  >(storage.specificThingsToInclude ?? []);

  const updateState = (newState: string[]) => {
    setSpecificThingsToInclude(newState);
    setStorageSearch({ specificThingsToInclude: newState });
  };

  const handleOnAddClick = (value: string) => {
    if (!value) return;
    if (specificThingsToInclude.includes(value)) {
      showToast('This was already added');
    }
    updateState([...specificThingsToInclude, value]);
    setInputValue('');
  };

  const handleOnChange = (index: number, value: string) => {
    const newSpecificThingsToInclude = [...specificThingsToInclude];
    newSpecificThingsToInclude[index] = value;
    updateState(newSpecificThingsToInclude);
  };

  const handleOnRemoveClick = (index: number) => {
    const newSpecificThingsToInclude = [...specificThingsToInclude];
    newSpecificThingsToInclude.splice(index, 1);
    updateState(newSpecificThingsToInclude);
  };

  return (
    <>
      {specificThingsToInclude.map((thing, index) => (
        <TailoringInput
          key={index}
          placeholder=""
          value={thing}
          setValue={(value) => handleOnChange(index, value)}
          handleOnRemoveClick={() => handleOnRemoveClick(index)}
          isHighlighted={true}
        />
      ))}
      {specificThingsToInclude.length < MAX_SPECIFIC_THINGS_TO_INCLUDE && (
        <TailoringInput
          placeholder="Specific events or landmarks?"
          value={inputValue}
          setValue={setInputValue}
          onBlur={() => handleOnAddClick(inputValue)}
          isHighlighted={false}
        />
      )}
    </>
  );
};
