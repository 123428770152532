import { useGetSitemap } from '@hooks/useGetSitemap';

export const SitemapPage = () => {
  const { sitemap, isSitemapLoading, sitemapError } = useGetSitemap();

  return (
    <>
      {isSitemapLoading && <div>Loading...</div>}
      {sitemapError && <div>Error: {sitemapError.message}</div>}
      {!isSitemapLoading && sitemap}
    </>
  );
};
