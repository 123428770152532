import { ChangeEvent, forwardRef } from 'react';
import { SpriteIcon } from '../icons/SpriteIcon';
import classNames from 'classnames';

type Props = {
  placeholder: string;
  value: string | null;
  setValue: (value: string) => void;
  onBlur?: () => void;
  handleOnRemoveClick?: () => void;
  isHighlighted?: boolean;
};

export const TailoringInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      value,
      setValue,
      onBlur,
      handleOnRemoveClick,
      isHighlighted,
    }: Props,
    ref,
  ) => {
    const inputWidth = value ? value.length * 12 : placeholder.length * 12;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    return (
      <div
        className={classNames(
          'flex items-center',
          'gap-x-1 p-4 py-[10px]',
          'rounded-40',
          'min-w-[82px] max-w-[343px]',
          {
            'bg-black': isHighlighted,
            'bg-gray-light': !isHighlighted,
          },
        )}
      >
        <input
          ref={ref}
          className={classNames(
            'appearance-none text-center',
            'placeholder:text-center placeholder-shown:text-left placeholder-purple caret-purple',
            'text-p whitespace-nowrap outline-none min-w-[82px] max-w-[343px] bg-transparent',
            'cursor-pointer',
            {
              'text-white bg-black': isHighlighted,
              'text-black bg-gray-light': !isHighlighted,
            },
          )}
          style={{
            width: `${inputWidth}px`,
          }}
          type="text"
          placeholder={placeholder}
          value={value ?? ''}
          onChange={handleChange}
          onBlur={onBlur}
        />
        {handleOnRemoveClick && (
          <SpriteIcon
            iconName="cross"
            onClick={handleOnRemoveClick}
            className={classNames('w-4 h-4 cursor-pointer', {
              'fill-white': isHighlighted,
              'fill-black': !isHighlighted,
            })}
          />
        )}
      </div>
    );
  },
);
