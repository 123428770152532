import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  paddingHorizontal?: number;
  fullHeight?: boolean;
  variant?: 'default' | 'secondary';
  styles?: string;
  mainStyles?: string;
};

export const Container = ({
  children,
  paddingHorizontal = 2,
  fullHeight = false,
  variant = 'default',
  styles,
  mainStyles,
}: Props) => {
  return (
    <main
      className={classNames(
        'relative flex flex-col h-full',
        `px-${paddingHorizontal}`,
        'mdx:px-4 gap-4',
        'mdx:grid mdm:grid-cols-12',
        styles,
      )}
    >
      <div
        className={classNames('hidden mdm:flex', {
          'mdx:col-span-3 lg:col-span-4': variant === 'default',
          'mdx:col-span-2 lg:col-span-2': variant === 'secondary',
        })}
      />
      <div
        className={classNames(
          'flex flex-col w-full',
          {
            'mdx:col-span-6 lg:col-span-4': variant === 'default',
            'mdx:col-span-8 lg:col-span-8': variant === 'secondary',
            'f-full': fullHeight,
          },
          mainStyles,
        )}
      >
        {children}
      </div>
      <div
        className={classNames('hidden mdm:flex', {
          'mdx:col-span-3 lg:col-span-4': variant === 'default',
          'mdx:col-span-2 lg:col-span-2': variant === 'secondary',
        })}
      />
    </main>
  );
};
