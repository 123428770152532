import { useApi } from "@/api/serviceProvider";
import { useQuery } from "@tanstack/react-query";
import fs from 'fs';

export const useGetSitemap = () => {
    const { api } = useApi();

    const { data, isLoading, error } = useQuery({
        queryKey: ['sitemap'],
        queryFn: async () => {
            const response = await api.getSitemap();
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.text();

            // update the sitemap
            const filePath = './public/sitemap.xml';

            fs.writeFileSync(filePath, data, 'utf8');
            console.log('fetchAndSaveSitemap func was executed successfully!');
            return data;
        },
        retry: 1,
        retryOnMount: false,
    });

    return {
        sitemap: data,
        isSitemapLoading: isLoading,
        sitemapError: error,
    }
};