import { create } from 'zustand';

import { Budget, Tailoring, TailoringAccommodation } from '@/generated';
import { FoodPreferenceType } from '@/types/FoodPreference';
import { ActivityTag } from '@/types/AppTypes';

export type TailoredSearchTypes = {
  companions?: Tailoring.companions;
  interests?: ActivityTag[];
  foodPreferences?: FoodPreferenceType[];
  diets?: FoodPreferenceType[];
  earliestStart?: string;
  dailyBudget?: Budget;
  accommodation?: TailoringAccommodation;
  specificThingsToInclude?: string[];
};

type State = {
  tailoredSearch: TailoredSearchTypes;
};

type Actions = {
  setTailoredSearch: (value: Partial<TailoredSearchTypes>) => void;
  resetTailoredSearch: () => void;
};

const initialState: TailoredSearchTypes = {
  companions: Tailoring.companions.NO_ONE,
};

export const useTailorSearchStore = create<State & Actions>((set) => ({
  tailoredSearch: initialState,
  setTailoredSearch: (value) =>
    set((state) => ({
      tailoredSearch: {
        ...state.tailoredSearch,
        ...value,
      },
    })),
  resetTailoredSearch: () =>
    set(() => ({
      tailoredSearch: initialState,
    })),
}));
