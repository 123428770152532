import { TabBarButton } from '@components/buttons/TabBarButton';
import { tabbarButtons } from '@data/appData/tabbarData';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

export const Tabbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentTab = tabbarButtons.find((tab) => tab.routeName === pathname);

  return (
    <div
      className={classNames(
        'flex justify-between w-full',
        'fixed mdx:hidden bottom-4',
        'bg-white rounded-36 p-2 shadow-bottom-bar',
      )}
    >
      {tabbarButtons.map(({ icon, value, routeName }) => (
        <TabBarButton
          key={value}
          icon={icon}
          isActive={currentTab?.value === value}
          onClick={() => navigate(routeName)}
        >
          {value}
        </TabBarButton>
      ))}
    </div>
  );
};
